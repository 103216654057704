import React from 'react'
import {graphql, Link} from 'gatsby'
import MainLayout from "../components/layoutmain";
import {GatsbyImage, getImage, StaticImage} from "gatsby-plugin-image"
import Tags from "../components/posts/tag";
import moment from "moment/dist/moment";
import "moment/dist/locale/fr"
import {FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, EmailShareButton, EmailIcon, TwitterShareButton, TwitterIcon} from "react-share";
import { useLocation } from "@reach/router";
import noPicture from "/src/images/no_picture.png"

const Template = ({data}) => {
    moment.locale('fr')

    const {markdownRemark} = data
    const title = capitalizeFirstLetter(markdownRemark.frontmatter.title)
    const tags = markdownRemark.frontmatter.tags
    const description = markdownRemark.frontmatter.description
    const creation_date = markdownRemark.frontmatter.creation_date
    const html = markdownRemark.html
    const author = markdownRemark.frontmatter.author
    const thumb = getImage(markdownRemark.frontmatter.thumb)

    const url = useLocation().href

    const seoProps = {
        title: title,
        description: description,
        image: (markdownRemark.frontmatter.thumb)
            ? markdownRemark.frontmatter.thumb.childImageSharp.fixed.src : noPicture
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <MainLayout seoProps={seoProps}>
            <div className={"actualites"}>
                <div className={"bloc-category bloc-presentation"}>
                    <div className={"top-news"}>
                        <div className={"image-container"}>
                            {(markdownRemark.frontmatter.thumb)
                                ? <GatsbyImage image={thumb} alt={title} className={"presentation-image-thumbnail"} />
                                : <StaticImage src={"../images/no_picture.png"} alt={title} className={"presentation-image-thumbnail"} />
                            }
                        </div>
                        <div className={"content-article"}>
                            <Tags tags={tags} />
                            <div className={"article-title"}><h2>{title}</h2></div>
                            <div className={"article-information"}>
                                <Tags tags={author} author />
                                <div className={"date"}>{moment(creation_date).format("DD MMMM YYYY")}</div>
                            </div>
                        </div>
                </div>
            </div>
                <div className={"bloc-category bloc-content"}>
                    <div className={"bloc-blog"}>
                        <div className='blogpost' dangerouslySetInnerHTML={{__html: html}}/>

                        <div className={"containerSharing"}>
                            <p>Partager cette article sur :</p>
                            <div>
                            <FacebookShareButton url={url} ><FacebookIcon size={32} round={true}/></FacebookShareButton>
                            <LinkedinShareButton url={url} ><LinkedinIcon size={32} round={true} /></LinkedinShareButton>
                            <EmailShareButton url={url} ><EmailIcon size={32} round={true} /></EmailShareButton>
                            <TwitterShareButton url={url} ><TwitterIcon size={32} round={true} /></TwitterShareButton>

                            </div>
                        </div>

                        <Link to="/actualites/" className={"backLink"}>{"< Retour à la liste des articles"}</Link>
                    </div>

                </div>

            </div>
        </MainLayout>
    )
}

export const query = graphql`
   query($id: String!) {
      markdownRemark(id: {eq: $id}){
        frontmatter {
          creation_date
          title
          description
          thumb {
            childImageSharp {
              gatsbyImageData
               fixed {
                src
               }
            }
          }
          tags
          status
          author {
            first_name
            last_name
          }
        }
         html
      }
    }
`

export default Template
