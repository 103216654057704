import React from "react";

const Tags = ({ tags, author = false, limit = 5, readingTime }) => {

    const styles = author ? "tag author" : "tag"

    if(tags){
        if(typeof tags === "string") {
            return (<div className={styles}>{author ? tags.first_name + " " + tags.last_name : tags}</div>)
        }else{
            return (
                <div className={"tags-container"}>
                    {
                        tags.slice(0, limit).map((tag) => {
                            return <div className={styles}>{author ? tag.first_name + " " + tag.last_name : tag}</div>
                        })
                    }
                </div>
            )
        }
    }

}

export default Tags;
